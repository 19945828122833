import { Button, Col, Drawer, message, Row, Spin, Space, Typography } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React from 'react';
import { trainLogService } from '../../helpers/services/trainlog';
import TestContent from "../content/TestContent";
import "../../index.css";

const { Text, Title } = Typography;

export const MainHeader = ({ page, setPage }) => {
  const [testVisiblity, setTestVisiblity] = React.useState(false);
  const [testLoader, setTestLoader] = React.useState(true);
  const [publishLoader, setPublishLoader] = React.useState(false);

  const testHandler = async () => {
    // for dev purpose only, following two lines need to bed uncommented
    // setTestLoader(false);
    // setTestVisiblity(true);
    // for production

      setTestLoader(true);
      setTestVisiblity(true);
      setTestLoader(false);

  };

  const publishHandler = async () => {
    try {
      setPublishLoader(true);
      const { data } = await trainLogService.publish();

      if (data.status === 'success') {
        message.success('Ажилттай');
      } else {
        message.error('Алдаа гарлаа', JSON.stringify(data));
      }
    } catch (e) {
      message.error('error:', e);
    } finally {
      setPublishLoader(false);
    }
  };

  return (
    <Header
      style={{
        backgroundColor: '#3E413F',
      }}
    >
      <Row className="items-center" justify="space-between">
        <Drawer
          title={<Col>
            <Row><Text type='primary'>Турших</Text></Row>
            <Row><Text type="secondary" className='text-sm'>Зөвхөн сургасан загварыг туршиж чатлах зориулалттай.</Text></Row>
          </Col>}
          open={testVisiblity}
          onClose={() => setTestVisiblity(false)}
          width={540}
          bodyStyle={{
            padding: "0px"
          }}
        >
          {testLoader ? <Spin /> : <TestContent />}
        </Drawer>
        <Col>
          {/* <img src="bot_logo.png" className='logo' /> */}
          <Typography.Title level={4}>
            <a href="/"><span className="text-white">Андаа туслах: Түгээмэл асуулт-хариулт</span></a>
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type={page === 'edit' ? 'primary' : 'default'}
            onClick={() => setPage('edit')}
          >
            БОЛОВСРУУЛАХ
          </Button>
          <Button
            type={page === 'train' ? 'primary' : 'default'}
            onClick={() => setPage('train')}
          >
            СУРГАХ
          </Button>
          <Button
            type={page === 'test' ? 'primary' : 'default'}
            onClick={testHandler}
          >
            ТУРШИХ
          </Button>
          <Button
            type={page === 'publish' ? 'primary' : 'default'}
            onClick={() => setPage('publish')}
          >
            НИЙТЛЭХ
          </Button>
          {/* <Button loading={publishLoader} onClick={publishHandler}>
            НИЙТЛЭХ
          </Button> */}
        </Col>
      </Row>
    </Header>
  );
};
