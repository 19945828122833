import { fetchWrapper } from '../fetchWrapper';
import { URL_ANSWER } from '../url';

export const answerService = { create, list, delete: _delete, update };

function list(params) {
  return fetchWrapper.get(URL_ANSWER, params);
}
function create(body) {
  return fetchWrapper.post(URL_ANSWER, body);
}
function update(id, body) {
  return fetchWrapper.put(URL_ANSWER + `/${id}`, body);
}
function _delete(id) {
  return fetchWrapper.delete(URL_ANSWER + `/${id}`);
}
