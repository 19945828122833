import { Button, Card, Col, Layout, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import EditContent from "../components/content/EditContent";
import PublishContent from "../components/content/PublishContent";
import TrainContent from "../components/content/TrainContent";
import GuideContent from "../components/content/GuideContent";
import { MainHeader } from "../components/headers/MainHeader";
import { MainFooter } from "../components/footers/MainFooter";
import axios from "axios";
import { OAUTH_CLIENT_ID } from "../helpers/constant";

const ContentRender = ({ page }) => {
  switch (page) {
    case "publish":
      return <TrainContent />;
    case "train":
      return <TrainContent />;
    case "guide":
      return <GuideContent />;
    default:
      return <EditContent />;
  }
};

const HomePage = () => {
  const [page, setPage] = useState("edit");
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");

  // For development comment following statement
  React.useEffect(() => {
    if(!loader) return
    if (token) {
      getUserData();
    } else {
      window.location.replace(
        `https://auth.num.edu.mn/oauth2/oauth/authorize?redirect_uri=https://faqedit.num.edu.mn/authorize&response_type=token&state=xyz&client_id=${OAUTH_CLIENT_ID}`
      );
    }
  });

  const getUserData = () => {
    var config = {
      method: "get",
      url: "https://auth.num.edu.mn/resource/me",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        let userData = {};
        response.data.forEach((element) => {
          userData[element.Type] = element.Value;
        });
        localStorage.setItem("user", JSON.stringify(userData));
        setLoader(!loader);
      })
      .catch(function (error) {
        console.log(error);
        window.location.replace(
          `https://auth.num.edu.mn/oauth2/oauth/authorize?redirect_uri=https://faqedit.num.edu.mn/authorize&response_type=token&state=xyz&client_id=${OAUTH_CLIENT_ID}`
        );
      });
  };

  return (
    <div>
      {/* {!loader ? ( // for production */}
      {!loader ? (
        <Layout style={{
          minHeight: "100vh"
        }}>
          <MainHeader page={page} setPage={setPage} />
          <Content
            className="p-5"
            style={{
              backgroundColor: "white",
            }}
          >
            <ContentRender page={page} />
          </Content>
          <MainFooter page={page} setPage={setPage} />
        </Layout>
      ) : (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
export default HomePage;
