import React from 'react';
import {
    Divider, 
    List, 
    Typography
  } from 'antd';

const GuideContent = () => {

    return (
        <div>
            <h1>Асуулт-хариулт боловсруулах зөвлөмж</h1>
            <p>
            Энэ заавраар хиймэл оюунд суурилсан МУИС-ийн дижитал туслах чатботод зориулж түгээмэл асуулт-хариултыг хэрхэн боловсруулахыг зөвлөнө. 
            </p>
            <Divider orientation="left">Асуулт боловсруулах</Divider>
            <List  >
                <List.Item>Нэг хариултыг олон янзын хувилбараар асуухад чатбот хариулах боломжтой учир нэг хариултад тохирох олон асуулт зохионо.</List.Item>
                <List.Item>
                    Асуултын хувилбарыг боловсруулахдаа дараах зарчмыг баримтална.
                    <List>
                        <List.Item>- Нэг утгатай асуултад багадаа 15 өөр хувилбар боловсруулна.</List.Item>
                        <List.Item>- Утга агуулгын хувьд нэг л хариулт гарч ирэхээр байна.</List.Item>
                        <List.Item>- Өгүүлбэрийн бүтэц, найруулга өөр байж болно.</List.Item>
                        <List.Item>- Оюутнууд яаж асуух бол гэдэгт үндэслэн зохионо.</List.Item>
                        <List.Item>- Үг үсгийн алдаагүй, Монгол кирилл үсгээр бичнэ.</List.Item>
                        <List.Item>- Нэг өгүүлбэрийг ижил утгатай үгсээр сольж бичнэ. Жишээ нь, багц цаг, кредит цаг гэх мэт.</List.Item>
                        <List.Item>- Бусад хариултад тохирох асуултын хувилбартай утгын хувьд давхцаж болохгүй.</List.Item>
                    </List>
                </List.Item>
            </List>
            <Divider orientation="left">Хариулт боловсруулах</Divider>
            <List >
                <List.Item>Дүрэм журмын заалт руу чиглүүлээд хаяхгүй байна.</List.Item>
                <List.Item>Магадгүй юу хийх, цаашид яахыг нь тодорхой зааж хэлэхээр байна.</List.Item>
                <List.Item>Аль болох дахин асуух асуулт үлдээхгүй байна.</List.Item>
                <List.Item>Мэдээж дүрэм журмыг нэмж зааж өгч болно. Энэ тохиолдолд холбогдох журмын нэр, заалтыг тодорхой бичнэ. Мөн дүрэм журмыг харах веб хаягийг (URL) зааж өгөх шаардлагатай.</List.Item>
                <List.Item>Тухайн асуултад холбогдох албан тушаалтан, мэргэжилтэн байгаа бол холбоо барих мэдээллийг зааж өгнө.</List.Item>
                <List.Item>Эелдэг, найрсаг уур амьсгал төрүүлэхээр байна. Emoji дүрс ашиглах нь харилцааг илүү найрсаг болгож өгдөг. https://emojipedia.org/ сайтаас утга агуулгаараа хайгаад шууд хуулаад тавьж болно.</List.Item>
                <List.Item>Markdown форматаар хариултаа хэлбэржүүлж болно. Ялангуяа, асуулттай холбоотой дэлгэрэнгүй мэдээллийг веб хуудсын хаягаар зааж өгвөл илүү тустай хариулт болно. Жишээ нь URL-ийг 
                    <List>
                        <List.Item>- [энд дарна уу](http://num.edu.mn) гэж оруулдаг. </List.Item>
                        <List.Item>- Мөн [энд дараад и-мэйл бичээрэй](mailto:amarsanaag@num.edu.mn)</List.Item>
                    </List>
                </List.Item>
                <List.Item>“... болох уу?” гэж асуугаад хариулт нь “Болно. ...” гэсэн маягтай байж болохгүй. Учир нь хүмүүс заавал болох уу? гэж асуухгүй байж болох ба энэ тохиолдолд хариулт нь жаахан ойлгомжгүй болдог.</List.Item>
            </List>
        </div>
    )
};

export default GuideContent;
