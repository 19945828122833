import { fetchWrapper } from '../fetchWrapper';
import { URL_TRAIN } from '../url';

export const trainLogService = {
  create,
  list,
  delete: _delete,
  update,
  test,
  publish,
};

function list(params) {
  return fetchWrapper.get(URL_TRAIN, params);
}
function create(body) {
  return fetchWrapper.post(URL_TRAIN, body);
}
function test(body) {
  return fetchWrapper.post(URL_TRAIN + '/test', body);
}
function publish(body) {
  return fetchWrapper.post(URL_TRAIN + '/publish', body);
}
function update(id, body) {
  return fetchWrapper.put(URL_TRAIN + `/${id}`, body);
}
function _delete(id) {
  return fetchWrapper.delete(URL_TRAIN + `/${id}`);
}
