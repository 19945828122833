import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { OAUTH_REDIRECT_URI, OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET } from "../helpers/constant";
import { Spin } from "antd";

const AuthorizePage = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().hash.replace("#", "?"));
  const token = query.get("access_token");


  React.useEffect(() => {
    if (token) {
      getUserData();
    } else {
      window.location.replace(
        `https://auth.num.edu.mn/oauth2/oauth/authorize?redirect_uri=https://faqedit.num.edu.mn/authorize&response_type=token&state=xyz&client_id=${OAUTH_CLIENT_ID}`
      );
    }
  });

  const getUserData = () => {
    var config = {
      method: "get",
      url: "https://auth.num.edu.mn/resource/me",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        let userData = {}
        response.data.forEach(element => {
          userData[element.Type] = element.Value
        });
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", token)
        navigate({ pathname: "/" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <Spin tip="Нэвтэрч байна..." size="large" />
    </div>
  );
};

export default AuthorizePage;
