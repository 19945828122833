import React from "react";
import moment from "moment";
import { Button, Col, message, Modal, Row, Skeleton, Space, Table, Tag, Tooltip, Popconfirm} from "antd";
import { DeleteOutlined, SyncOutlined, InfoCircleOutlined, QuestionCircleOutlined, UploadOutlined, HistoryOutlined } from "@ant-design/icons";
import { trainLogService } from "../../helpers/services/trainlog";

const TrainContent = () => {
  const [trainLogList, setTrainLogList] = React.useState();
  const [loaderTrain, setLoaderTrain] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [publishLoader, setPublishLoader] = React.useState(false);
  const [infoModal, setInfoModal] = React.useState(false);

  React.useEffect(() => {
    if (trainLogList) return;
    getTrainLogList();
  });

  const getTrainLogList = async () => {
    if (loader) return;
    setLoader(true);
    try {
      const { data } = await trainLogService.list();
      setTrainLogList(data?.data);
    } catch (err) {
      message.error("error:", err);
    } finally {
      setLoader(false);
    }
  };

  const trainDataHandler = async () => {
    if (loaderTrain) return;
    setLoaderTrain(true);
    try {
      const { data } = await trainLogService.create();

      if (data.status === "success") {
        message.success("Сургалтыг амжилттай эхлүүллээ. Та шалгах товч дарж дууссан эсэхийг нягтлаарай.");
      } else {
        message.error("Сургалтыг эхлүүлэх явцад алдаа гарлаа. Та дахин оролдоод болохгүй бол системийн админд хандана уу.", JSON.stringify(data));
      }
    } catch (err) {
      message.error("error:", err);
    } finally {
      setLoaderTrain(false);
      getTrainLogList();
    }
  };

  const qnaDeleteHandler = async (id) => {
    try {
      const { data } = await trainLogService.delete(id);

      if (data.status === "success") {
        message.success("Загварыг амжилттай устгалаа.");

        var contain = trainLogList.filter((value) => {
          return value._id !== id;
        });

        setTrainLogList(contain);
      } else {
        message.error("Сургалтын загварыг устгах явцад алдаа гарлаа. Та дахин оролдоод болохгүй бол системийн админд хандана уу.", JSON.stringify(data));
      }
    } catch (err) {
      message.error("error:", err);
    }
  };

  const publishHandler = async (id) => {
    try {
      setPublishLoader(id);
      const { data } = await trainLogService.publish({
        id: id
      });

      if (data.status === "success") {
        message.success("Андаа туслахын одоогийн загварыг сургалтын загвараар солих ажлыг амжилттай эхлүүллээ. Нийтлэх үйлдэл дууссаны дараа сургалтын загварын төлөв нь НИЙТЭЛСЭН болно.");
      } else {
        message.error("Сургалтын загварыг нийтлэх явцад алдаа гарлаа. Та дахин оролдож үзээд болохгүй бол системийн админд хандана уу.", JSON.stringify(data));
      }
    } catch (e) {
      message.error("error:", e);
    } finally {
      setPublishLoader(false);
    }
  };

  const columns = [
    {
      title: () => (
          <Tooltip title="Машин сургалтын загварыг сургаж байгаа, сургасан болон Андаа туслахад нийтэлсэн тухай мэдээлэл." placement="topRight">
            Төлөв <InfoCircleOutlined style={{ verticalAlign: 'middle', }} /> 
          </Tooltip>
      ),
      dataIndex: "train_status",
      key: "train_status",
      render: (train_status) => {
        switch (train_status) {
          case "SUCCESS":
            return <Tag color="geekblue" key="success">СУРГАСАН</Tag>;
          case "TRAINING":
            return <Tag color="volcano" key="training">СУРГАЖ БАЙНА</Tag>;
          case "PUBLISHED":
            return <Tag color="green" key="published">НИЙТЭЛСЭН</Tag>;
        }
      },
    },
    {
      title: (
        <Tooltip title="Машин сургалтын загварыг сургаж эхэлсэн огноо, цаг." >
          Эхэлсэн <InfoCircleOutlined style={{ verticalAlign: 'middle', }} /> 
        </Tooltip>
    ),
      dataIndex: "start_at",
      key: "start_at",
      render: (date) => <>{moment(date).format("YYYY-MM-DD HH:MM")}</>,
    },
    {
      title: (
        <Tooltip title="Машин сургалтын загварыг сургаж дууссан бол дууссан огноо, цаг гарна." >
          Дууссан <InfoCircleOutlined style={{ verticalAlign: 'middle', }} /> 
        </Tooltip>
    ),
      dataIndex: "end_at",
      key: "end_at",
      render: (date) => <>{date ? moment(date).format("YYYY-MM-DD HH:MM") : "Дуусаагүй байна"}</>,
    },
    {
      title: (
        <Tooltip title="Сургалтыг эхлүүлсэн хэрэглэгчийн нэр, албан тушаал." >
          Сургасан <InfoCircleOutlined style={{ verticalAlign: 'middle', }} /> 
        </Tooltip>
    ),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy) => (
        <>
          {createdBy && (
            <div>
              {createdBy?.split("~")[0].charAt(0)}. {createdBy?.split("~")[1]}
              <br></br>
              {createdBy?.split("~")[2].replace("Монгол Улсын Их Сургууль -", "")}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (_, record) => (
        <Space size={"middle"}>
          <Popconfirm
                placement="top"
                title="Та сургасан загварыг устгахдаа итгэлтэй байна уу?"
                description="Нэгэнт устгасан загварыг буцааж сэргээх боломжгүй."
                onConfirm={()=>{
                  qnaDeleteHandler(record?._id);
                }}
                okType="danger"
                okText="Устгах"
                cancelText="Болих"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                
              >
              <Button
                danger
                icon={<DeleteOutlined />}
              ></Button>
          </Popconfirm>
          <Popconfirm
                placement="top"
                title="Та сургасан загварыг устгахдаа итгэлтэй байна уу?"
                description="Нэгэнт устгасан загварыг буцааж сэргээх боломжгүй."
                onConfirm={()=>{
                  publishHandler(record?._id);
                }}
                okText="Нийтлэх"
                icon={<QuestionCircleOutlined />}
                cancelText="Болих"
              >
              <Button
                type="primary"
                loading={publishLoader===record?._id ? true : false}
                icon={<UploadOutlined />}
              ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Modal footer={[]} open={infoModal} onCancel={() => setInfoModal(false)}>
        Info
      </Modal>
      <Row justify="space-between">
        <Col className="p-5">
          <h1 className="text-xl">Машин сургах</h1>
          <p>
            Энэ нь шинээр нэмж боловсруулсан асуулт-хариултыг хиймэл оюунаар сургах команд юм. Сервер компьютерийн хүчин чадлаас
            хамаарч хэдэн арван минут болох тул байн байн сургах нь хугацаа алдаж болзошгүй. Мөн асуулт-хариулт засварлах
            боломжтой олон хэрэглэгчдээс хэн нэг нь сургалтыг эхлүүлсэн, сургалт одоо үргэлжилж байгаа тохиолдолд дуусахаас өмнө
            та дахин сургах боломжгүйг анхаарна уу. Сургалтыг дууссан эсэхийг дараах "Шалгах" товчийг дарж мэдээрэй.
          </p>
          <div>
            {trainLogList?.length > 0 && trainLogList[0].train_status === "TRAINING" ? (
              <Button type="primary" loading>
                Сургаж байна
              </Button>
            ) : (
              
              <Popconfirm
                placement="top"
                title="Та сургалт эхлүүлэхдээ итгэлтэй байна уу?"
                description="Асуулт-хариултын тооноос хамаарч хэдэн арван минут үргэлжлэнэ."
                onConfirm={()=>{
                  trainDataHandler();
                }}
                icon={<QuestionCircleOutlined />}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  type="primary"
                  loading={loaderTrain}
                  icon={<HistoryOutlined style={{ verticalAlign: 'middle', }}/>}
                >Сургах</Button>
              </Popconfirm>
            )}{" "}
            &nbsp;
            <Button loading={loader} icon={<SyncOutlined style={{ verticalAlign: 'middle', }} />} onClick={getTrainLogList}>
              Шалгах
            </Button>

          </div>
        </Col>
      </Row>
      <Row>
        {trainLogList && !loader ? (
          <Col className="w-full p-5">
            <h2 className="my-3">Сургалтын загвар</h2>
            {/* <div className="flex mb-1">
              <Button onClick={() => setInfoModal(true)} icon={<InfoCircleOutlined />}></Button>
            </div> */}
            <Table className="border" dataSource={trainLogList} columns={columns} />
          </Col>
        ) : (
          <Skeleton />
        )}
      </Row>
    </div>
  );
};

export default TrainContent;
