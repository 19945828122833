import { Button, Col, Divider, Form, Input, Modal, Row, message, Skeleton, Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import { XMark } from "../../helpers/Icons";
import { answerService } from "../../helpers/services/answer";
import { ObjectID } from "bson";
import ReactMarkdown from "react-markdown";

const EditContent = () => {
  const [form, setForm] = useState();
  const [loader, setLoader] = useState(false);
  const [answerList, setAnswerList] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (answerList) return;
    getAnswerList();
  }, []);

  const getAnswerList = async (offset = 1) => {
    setLoader(true);
    try {
      const { data } = await answerService.list({
        limit: limit,
        offset,
        filter: JSON.stringify({ q: search }),
      });
      setAnswerList(data?.data);
      setTotal(data?.totalCount);
    } catch (err) {
      message.error("error:", err);
    } finally {
      setLoader(false);
    }
  };

  const qnaDeleteHandler = async (id) => {
    try {
      const { data } = await answerService.delete(id);

      if (data.status === "success") {
        message.success("Ажилттай");

        var contain = answerList.filter((value) => {
          return value._id !== id;
        });

        setAnswerList(contain);
      } else {
        message.error("Алдаа гарлаа", JSON.stringify(data));
      }
    } catch (err) {
      message.error("error:", err);
    }
  };

  const qnaUpdateHandler = (body) => {
    setForm(body);
  };

  const onModalOk = async () => {
    setLoader(true);
    try {
      if (form?.questions?.length === 0) {
        message.error("Багадаа 1 асуулт оруулах ёстой");
        return;
      }
      if (form?.answers?.length === 0) {
        message.error("Хариулт оруулаагүй байна ");
        return;
      }

      if (form?._id) {
        // update
        var { data } = await answerService.update(form._id, form);
      } else {
        // create
        // eslint-disable-next-line no-redeclare
        var { data } = await answerService.create(form);
      }

      if (data.status === "success") {
        message.success("Амжилттай");
      } else {
        message.error("error:", JSON.stringify(data));
      }
    } catch (err) {
      message.error(err);
    } finally {
      getAnswerList();
      setLoader(false);
      setForm();
    }
  };

  const onSearch = () => {
    console.log(search);
    getAnswerList();
  };

  return (
    <Col>
      <Row>
        <h1 className="text-xl">Асуулт-хариултын сан</h1>
      </Row>
      <Row
        className="p-5"
        style={{
          backgroundColor: "#F0F2F5",
        }}
      >
        <Col span={12} className="border">
          <Input.Search
            placeholder="Хайх утгаа оруулна уу"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
            onSearch={onSearch}
          />
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Button
              onClick={() => {
                if (form) {
                  return;
                } else {
                  setAnswerList(
                    [
                      {
                        questions: [
                          {
                            question: "",
                          },
                        ],
                        answers: [{ answer: "" }],
                      },
                    ].concat(answerList)
                  );
                  qnaUpdateHandler({
                    questions: [],
                    answers: [{ answer: "" }],
                  });
                }
              }}
            >
              <div className="flex items-center">
                <PlusOutlined />
                <span className="ml-2">Асуулт-хариулт нэмэх</span>
              </div>
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="p-5">
        <Col span={24}>
          <div className="mb-3 grid grid-cols-3">
            <Row></Row>
            <Row justify="center">
              <Col>
                <Pagination
                  onChange={(e) => {
                    setCurrentPage(e);
                    getAnswerList(e);
                  }}
                  showSizeChanger={false}
                  pageSize={limit}
                  current={currentPage}
                  total={total}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Select
                defaultValue={limit}
                onChange={(e) => {
                  setLimit(e);
                }}
              >
                <Select.Option key={10}>10</Select.Option>
                <Select.Option key={20}>20</Select.Option>
                <Select.Option key={30}>30</Select.Option>
              </Select>
            </Row>
          </div>

          <Row>
            <Col span={12}>
              <h1>Асуултууд</h1>
            </Col>
            <Col span={12}>
              <h1>Хариулт</h1>
            </Col>
          </Row>
          <Divider />

          <div className="grid grid-cols-1 gap-5">
            {answerList && !loader ? (
              answerList?.map((value, index) => {
                if (form?._id === value?._id) {
                  return (
                    <Row key={index} className="border-b">
                      <Col span={12}>
                        <ul>
                          {form?.questions?.map((subvalue, subindex) => {
                            return (
                              <li className=" mx-2 mb-2" key={subindex}>
                                {/* <p key={subindex} className="inline-block border px-2 mr-2 mb-2 text-gray-600">
                                  {subvalue.question}
                                </p> */}
                                <div className="flex justify-center items-center">
                                  <span className="w-8 text-center">{subindex + 1}.</span>
                                  <Input
                                    className="ml-3"
                                    value={subvalue?.question}
                                    onChange={(e) => {
                                      let newvalue = form?.questions?.map((obj, qIndex) => {
                                        if (qIndex === subindex) {
                                          return { ...obj, question: e.target.value };
                                        } else {
                                          return obj;
                                        }
                                      });
                                      setForm({
                                        ...form,
                                        questions: newvalue,
                                      });
                                    }}
                                  />
                                  <Button
                                    className="mx-3"
                                    onClick={() => {
                                      let newvalue = form?.questions?.filter((obj) => obj._id !== subvalue?._id);
                                      setForm({
                                        ...form,
                                        questions: newvalue,
                                      });
                                    }}
                                    danger
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </div>
                              </li>
                            );
                          })}
                          <li>
                            <Row justify="center">
                              <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  const object = {
                                    question: "",
                                  };
                                  setForm({
                                    ...form,
                                    questions: [...form?.questions, object],
                                  });
                                }}
                              >
                                Асуулт нэмэх
                              </Button>
                            </Row>
                          </li>
                        </ul>
                      </Col>
                      <Col span={12}>
                        {/* <ReactMarkdown>{value?.answers[0]?.answer}</ReactMarkdown> */}
                        <div data-color-mode="light">
                          <MDEditor
                            height={300}
                            value={form?.answers ? form?.answers[0].answer : ""}
                            onChange={(e) => {
                              setForm((prevState) => ({
                                ...prevState,
                                answers: [{ answer: e }],
                              }));
                            }}
                          />
                        </div>

                        <div className="my-3 grid grid-cols-3 items-center">
                          <Col className="col-span-2">
                            Сүүлд шинэчилсэн: <br></br>
                            {form?.updatedBy && (
                              <div className="pl-2">
                                <strong>
                                  {form?.updatedBy.split("~")[0].charAt(0)}.{form.updatedBy.split("~")[1]} <br></br>
                                </strong>
                                {form?.updatedBy.split("~")[2].replace("Монгол Улсын Их Сургууль -", "")}
                              </div>
                            )}
                          </Col>
                          <Button loading={loader} type="primary" onClick={() => onModalOk()}>
                            Хадгалах
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row key={index} className="border-b">
                      <Col span={12}>
                        <ul>
                          {value?.questions?.map((subvalue, subindex) => {
                            return (
                              <li className="list-decimal mx-2">
                                <p key={subindex} className="inline-block border px-2 mr-2 mb-2 text-gray-600">
                                  {subvalue.question}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                      <Col span={10}>
                        <ReactMarkdown>{value?.answers[0]?.answer}</ReactMarkdown>
                      </Col>
                      <Col span={2}>
                        <Row justify="space-evenly">
                          <Button
                            onClick={() => {
                              if (window.confirm("Та устгахдаа итгэлтэй байна уу")) {
                                qnaDeleteHandler(value?._id);
                              }
                            }}
                            danger
                            icon={<DeleteOutlined />}
                          ></Button>
                          <Button onClick={() => qnaUpdateHandler(value)} icon={<EditOutlined />}></Button>
                        </Row>
                      </Col>
                    </Row>
                  );
                }
              })
            ) : (
              <Skeleton />
            )}

            <div className="mb-3 grid grid-cols-3">
              <Row></Row>
              <Row justify="center">
                <Col>
                  <Pagination
                    onChange={(e) => {
                      setCurrentPage(e);
                      getAnswerList(e);
                    }}
                    showSizeChanger={false}
                    pageSize={limit}
                    current={currentPage}
                    total={total}
                  />
                </Col>
              </Row>
              <Row justify="end">
                <Select
                  defaultValue={limit}
                  onChange={(e) => {
                    setLimit(e);
                  }}
                >
                  <Select.Option key={10}>10</Select.Option>
                  <Select.Option key={20}>20</Select.Option>
                  <Select.Option key={30}>30</Select.Option>
                </Select>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default EditContent;
