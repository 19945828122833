import axios from "axios";
import { MAIN_URL } from "./url";

export const fetchWrapper = { get, post, put, delete: _delete };

const getToken = () => {
  return localStorage.getItem("token");
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

async function get(sub_url, params) {
  const token = getToken();

  return await axios
    .get(MAIN_URL + sub_url, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "mn",
        // Authorization: 'Bearer ' + token,
      },
      params,
    })
    .then(res)
    .catch(err);
}
async function post(sub_url, body) {
  const token = getToken();
  const user = getUser();

  return await axios
    .post(
      MAIN_URL + sub_url,
      {
        ...body,
        createdBy: `${user?.lnamem} ~ ${user?.fnamem} ~ ${user?.un}`,
        updatedBy: `${user?.lnamem} ~ ${user?.fnamem} ~ ${user?.un}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "mn",
          //   Authorization: 'Bearer ' + token,
        },
      }
    )
    .then(res)
    .catch(err);
}
async function put(sub_url, body) {
  const token = getToken();
  const user = getUser();

  return await axios
    .put(
      MAIN_URL + sub_url,
      { ...body, updatedBy: `${user?.lnamem} ~ ${user?.fnamem} ~ ${user?.un}` },
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "mn",
          //   Authorization: 'Bearer ' + token,
        },
      }
    )
    .then(res)
    .catch(err);
}
async function _delete(sub_url) {
  const token = getToken();

  return await axios
    .delete(MAIN_URL + sub_url, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "mn",
        // Authorization: 'Bearer ' + token,
      },
    })
    .then(res)
    .catch(err);
}

async function res(data) {
  return data;
}
async function err(error) {
  return { data: error };
}
