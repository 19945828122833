import { Button, Col, Drawer, message, Row, Spin, Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";

export const MainFooter = ({ page, setPage }) => {
  return (
    <Footer style={{ textAlign: "center" }}>
      <Row className="items-center" justify="space-between">
        <Button type="link" onClick={() => setPage("guide")}>
          Тусламж
        </Button>
        <Typography.Text level={4}>Дижитал МУИС © 2022</Typography.Text>
      </Row>
    </Footer>
  );
};
