import React, { useMemo } from 'react';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import { BF_TOKEN } from "../../helpers/constant";


const TestContent = () => {
    const directLine = useMemo(() => createDirectLine({ token: BF_TOKEN }), []);
    const styleOptions = {
        hideUploadButton: true,
        botAvatarInitials: '',
        botAvatarImage: 'bot_logo.png',
        avatarBorderRadius: '50%',
        avatarSize: 40,
        emojiSet: true,
        transitionDuration: '500ms',
        primaryFont: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Web', sans-serif;",
        monospaceFont: "'Consolas', 'Courier New', 'monospace'",
        backgroundColor: 'rgb(245, 245, 245)',
        fontSizeSmall: '80%',
        bubbleBorderWidth: 0.25,
        bubbleBorderRadius: 5,
        bubbleTextColor: 'rgb(36, 36, 36)',
        bubbleFromUserTextColor: 'rgb(36, 36, 36)',
        bubbleFromUserBackground: 'rgb(232, 235, 250)',
        sendBoxTextColor: '#33333',
        bubbleBorderColor: '#E9E9E9',
        bubbleFromUserBorderRadius: 5,
        bubbleFromUserBorderColor: '#E9E9E9',
        bubbleMaxWidth: 600,
        bubbleMinWidth: 480,
        cardEmphasisBackgroundColor: '#F0F0F0',
        cardPushButtonBackgroundColor: '#0063B1',
        cardPushButtonTextColor: 'Green',
        richCardWrapTitle: false,
        // Suggested actions carousel layout
        suggestedActionsCarouselFlipperCursor: undefined,
        suggestedActionsCarouselFlipperBoxWidth: 100,
        suggestedActionsCarouselFlipperSize: 100,
    }
    return (
        <ReactWebChat 
            directLine={directLine} 
            styleOptions={styleOptions}
            sendTypingIndicator={false}
        />
    );
};

export default TestContent;
