import React from "react";
import HomePage from "./pages/HomePage";
import "antd/dist/antd.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthorizePage from "./pages/AuthorizePage";
import ErrorRoutePage from "./pages/ErrorRoutePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/authorize",
    element: <AuthorizePage />,
  },
]);

function App() {
  React.useEffect(() => {
    document.title = "Андаа туслах: Түгээмэл асуулт-хариулт";
  });
  return (
    <RouterProvider router={router} />
  );
}

export default App;
